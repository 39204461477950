import type { Transport, Transporter } from "@sveltejs/kit";
import { Option, Schema } from "effect";
import { DateTime } from "luxon";

const createSchemaTransporter = <A, I>(schema: Schema.Schema<A, I>): Transporter<A, I> => {
    const encode = Schema.encodeUnknownOption(schema);
    const decode = Schema.decodeSync(schema);
    return {
        encode: (value: unknown) => encode(value).pipe(Option.getOrElse(() => false as const)),
        decode,
    };
};

export const transport = {
    Option: createSchemaTransporter(Schema.Option(Schema.Unknown)),
    LuxonDateTime: {
        encode: (value: unknown) => DateTime.isDateTime(value) && value.toISO(),
        decode: (value: string) => DateTime.fromISO(value),
    },
} satisfies Transport;
