import { environment, isLocal } from "$lib/env.js";

export const isSentryEnabled = true;
export const sharedSentryConfig = {
    enabled: !isLocal,
    dsn: "https://0868f5a8df6a6f970e2fb38922407bee@o4506671581298688.ingest.sentry.io/4506671581429760",
    profilesSampleRate: 0.005,
    tracesSampleRate: 0.1,
    // 100% of errors
    sampleRate: 1,
    environment,
    maxValueLength: 2000,
};
