import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,2,5,6,8,9,10,11];

export const dictionary = {
		"/": [~12],
		"/auth/change-provider/[provider=provider]": [~13],
		"/dashboard/(dashboard)": [14,[2]],
		"/dashboard/actions": [15,[2]],
		"/dashboard/actions/[actionId]/[sessionId]": [16,[2]],
		"/dashboard/assist": [17,[2]],
		"/dashboard/billing": [~18,[2,3]],
		"/dashboard/billing/success": [19,[2,3]],
		"/dashboard/calendar-log": [21,[2]],
		"/dashboard/calendar": [20,[2]],
		"/dashboard/contacts": [22,[2,4]],
		"/dashboard/contacts/[id]": [23,[2,4]],
		"/dashboard/emails": [24,[2]],
		"/dashboard/emails/[[folderExec]]/[folder]": [25,[2,5]],
		"/dashboard/schedule": [26,[2]],
		"/dashboard/schedule/new": [28,[2]],
		"/dashboard/schedule/[scheduleId]": [27,[2]],
		"/dashboard/settings": [~29,[2]],
		"/dashboard/settings/calendar/[execId]": [30,[2]],
		"/dashboard/tasks": [31,[2]],
		"/dashboard/tasks/new": [34,[2]],
		"/dashboard/tasks/[agendaId]": [32,[2]],
		"/dashboard/tasks/[agendaId]/view": [33,[2]],
		"/dashboard/team": [35,[2]],
		"/dashboard/team/add": [36,[2]],
		"/internal": [37,[6]],
		"/internal/organizations": [~38,[6]],
		"/internal/organizations/[orgId]": [~39,[6]],
		"/internal/users": [~40,[6]],
		"/internal/users/[execId]": [~41,[6]],
		"/invite/[shortCode]": [42,[,8],[7]],
		"/invite/[shortCode]/success": [43,[,8],[7]],
		"/join-org/[orgId]/[inviteId]/(welcome)": [44,[9]],
		"/join-org/[orgId]/[inviteId]/permissions": [45,[9]],
		"/login": [46],
		"/login/google": [47],
		"/welcome/(welcome)": [48,[10]],
		"/welcome/(with-org)/calendars": [49,[10,11]],
		"/welcome/(with-org)/done": [50,[10,11]],
		"/welcome/(with-org)/emails": [51,[10,11]],
		"/welcome/organization": [55,[10]],
		"/welcome/(with-org)/services": [52,[10,11]],
		"/welcome/(with-org)/team": [53,[10,11]],
		"/welcome/(with-org)/upgrade": [~54,[10,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';