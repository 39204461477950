import * as Sentry from "@sentry/sveltekit";
import { sharedSentryConfig } from "./instrument.js";

Sentry.init({
    ...sharedSentryConfig,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    integrations: [],
    ignoreErrors: ["TypeError: Failed to fetch"],
});
